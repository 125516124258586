import {
  IAuthPaymentRequest,
  IAuthPaymentResponse,
} from '../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Checkout'
import { IMorCartPrice } from '../Tenant/Context/MorCartContext'
import { IValidatedCartItem } from '../Tenant/Layout/Cart/UnavailableItemsModal'
import { ICheckoutFormState } from '../Tenant/Layout/Checkout'
import { IStateOption } from '../Tenant/Layout/Checkout/Checkout'
import { US_STATES } from '../Tenant/Layout/Checkout/Constants'
import { AVAILABLE_CONFIGS, IStudioPublicConfigs } from '../Tenant/Services/CMS/publicConfigs'
import { ICartItem } from '../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Cart'
import { getCookie } from '../../utils/cookie'
import { ILocationCookieType } from '../StoreLocator/interfaces'
import moment from 'moment'
import { IMorAuthRequest } from '../../../pages/checkout'
import { IFinancePromotionOffer } from '../Tenant/Services/GERS/Associate/customer'
import { IDbuyCompleteResponse } from '../Tenant/Services/GERS/Associate/synchrony-financial'
import { convertToUTC, getDatePickerNumberOfDays, getDatesInBetween, getSafeMomentObject } from './dateFormatter'
import { IDates } from '../Tenant/Services/GERS/Cart/delivery'
import { CMS_API_CALLS } from '../Tenant/Services/CMS'
import { EMAIL_REGEX, PHONE_REGEX } from '../../settings/variables'
import { ITransport } from '../Tenant/Services/CMS/blog'
import logProviderFactory from '../../utils/logs/logProviderFactory'

const AVAILABILITY_END_DATE = '2040-04-04'
const IN_STOCK = 'In Stock.'

interface IDatesFromCart {
  allItemsInStock: boolean
  estimatedDeliveryToUTCDate: Date | null
  lastAvailableDeliveryDateUTC: Date | null
  lastLeadTimeDate: Date
  unavailableDates: Date[]
}

const isMorCreditCardFeatureEnabled = async (configurations: IStudioPublicConfigs[]): Promise<boolean> => {
  return await CMS_API_CALLS.PUBLIC_CONFIGS.getPublicConfigsForIpAddress(
    configurations,
    AVAILABLE_CONFIGS.MOR_CREDIT_CARD,
  )
}

const isNeedByDateFeatureEnabled = async (configurations: IStudioPublicConfigs[]): Promise<boolean> => {
  return await CMS_API_CALLS.PUBLIC_CONFIGS.getPublicConfigsForIpAddress(configurations, AVAILABLE_CONFIGS.NEED_BY_DATE)
}

const doGetPaymentAuthRequest = (
  cartPrice: IMorCartPrice | null,
  formsState: ICheckoutFormState,
  sameBilling: boolean,
  cartItems: IValidatedCartItem[],
): IAuthPaymentRequest | null => {
  if (cartPrice) {
    const authPaymentRequestBody = formsState
    delete authPaymentRequestBody.needByDateForm

    const creditCardNumber = authPaymentRequestBody?.creditCard?.creditCardNumber?.toString()?.replace(/\s/g, '')
    authPaymentRequestBody.creditCard.creditCardNumber = Number(creditCardNumber)
    const authPaymentRequest: IAuthPaymentRequest = {
      ...JSON.parse(JSON.stringify(authPaymentRequestBody)),
    }
    if (sameBilling) {
      authPaymentRequest.billing = authPaymentRequest.shipping
    }
    authPaymentRequest.cart = {
      items: cartItems,
      pricing: cartPrice,
    }
    authPaymentRequest.description = 'my-description'
    return authPaymentRequest
  }
  return null
}

const parseAuthResponse = (response: IAuthPaymentResponse) => {
  let errorMessage: string = ''
  if (response.error) {
    if (typeof response.error === 'string') {
      errorMessage = response.error
    }

    const { messages, transactionResponse } = response.error
    if (messages?.resultCode === 'Error' && messages.message?.length) {
      errorMessage = messages.message[0].text
    }
    if (transactionResponse?.errors?.error?.length) {
      errorMessage = transactionResponse.errors.error[0].errorText
    }
  }

  if (response?.data) {
    const { transactionResponse } = response.data
    if (transactionResponse?.errors?.error?.length) {
      errorMessage = transactionResponse.errors.error[0].errorText
    }
  }
  return errorMessage
}

const creditCardFormHasError = (formsState: ICheckoutFormState) => {
  let hasErrors = false
  const { creditCard } = formsState
  if (
    !creditCard.creditCardNumber ||
    !creditCard.creditCardExpMonth ||
    !creditCard.creditCardExpYear ||
    !creditCard.creditCardCVV
  ) {
    hasErrors = true
  } else {
    hasErrors = false
  }

  return hasErrors
}

const validateBilling = (formsState: ICheckoutFormState, sameBilling: boolean) => {
  const { billing } = formsState
  return (billing?.lastName &&
    billing?.firstName &&
    billing?.street1 &&
    billing?.state &&
    billing?.zip &&
    billing?.city) ||
    sameBilling
    ? true
    : false
}

const updateSelectedState = (response: { state: string }) => {
  const states = US_STATES.map((s: IStateOption) => {
    const copiedState = Object.assign({}, s)
    if (s.value === response.state) {
      copiedState.selected = true
    }
    return copiedState
  })
  return states
}

const validateShipping = (formsState: ICheckoutFormState) => {
  const { shipping } = formsState
  return shipping?.lastName &&
    shipping?.firstName &&
    shipping?.street1 &&
    shipping?.state &&
    shipping?.zip &&
    shipping?.city
    ? true
    : false
}

const validateContactInfo = (formsState: ICheckoutFormState) => {
  const { contactInfo } = formsState
  const { email, phone } = contactInfo
  const isEmailValid = email && EMAIL_REGEX.test(email) && email.length <= 60
  const isPhoneValid = phone && PHONE_REGEX.test(phone) && phone.length === 14
  return isEmailValid && isPhoneValid ? true : false
}

const getUnavailableItems = (cartItems: IValidatedCartItem[]) => {
  return cartItems.filter(item => !item.product.available)
}

const getZipInitialValue = () => {
  const { zip } = JSON.parse(getCookie(ILocationCookieType.GEOLOCATION, null))
  return zip
}

const isValidShipping = (required: string[], obj: any) => {
  let count: number = 0
  for (const prop of required) {
    if (obj[prop] !== undefined && obj[prop] !== '') {
      count += 1
    }
  }
  return count >= required.length
}

const isBeforeAvailabilityEndDay = (date?: string | Date | null): boolean => {
  return date ? getSafeMomentObject(date).isBefore(AVAILABILITY_END_DATE, 'day') : false
}

/**
 * Return today’s date + lead time weeks
 * @param items {{ leadTime: number }[]}
 * @returns {Date}
 */
const getDateByLastLeadTime = (items: { leadTime: number }[]): Date => {
  const reducer = (accumulator: number, currentValue: { leadTime: number }): number => {
    if (currentValue?.leadTime > accumulator) {
      accumulator = currentValue?.leadTime
    }
    return accumulator
  }

  const latestLeadTimeWeeks = items.reduce(reducer, 0)

  return moment()
    .add(latestLeadTimeWeeks, 'weeks')
    .toDate()
}

const getUnavailableDates = (pickupDeliveryDates?: IDates[]) => {
  if (!pickupDeliveryDates) {
    return []
  }
  const numberOfDays = getDatePickerNumberOfDays()
  const startDate = new Date()
  const endDate = moment(new Date()).add(numberOfDays, 'day')

  let datesInBetween = getDatesInBetween(startDate, endDate)

  datesInBetween.filter(dateInBetween => {
    const availableDatesFiltered = pickupDeliveryDates?.filter(
      pickupDeliveryDate => moment(dateInBetween).isSame(pickupDeliveryDate.date) && pickupDeliveryDate.available,
    )

    return availableDatesFiltered.length > 0 ? true : false
  })

  pickupDeliveryDates?.forEach((d: IDates) => {
    if (d.available) {
      datesInBetween = datesInBetween.filter(dateInBetween => !moment(dateInBetween).isSame(d.date))
    }
  })

  return datesInBetween.map(ud => {
    const parts = ud.split('-')
    return new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]))
  })
}

const loadDatesFromCart = (response: IMorCartPrice): IDatesFromCart => {
  let estimatedDeliveryToUTCDate = null
  let lastAvailableDeliveryDateUTC = null
  let estimatedDelivery = null

  const lastLeadTimeDate = getDateByLastLeadTime(response.items)
  const unavailableDates = getUnavailableDates(response?.pickupDeliveryDates?.dates)

  const nextAvailableDate = response?.pickupDeliveryDates?.dates.find((d: IDates) => d.available)
  estimatedDelivery = nextAvailableDate?.date ? nextAvailableDate?.date : null
  estimatedDeliveryToUTCDate = convertToUTC(estimatedDelivery || '')

  const outOfStockItems = response.items.find(
    (item: { availableMessage: string; availableDate: string }) =>
      item.availableMessage !== IN_STOCK && isBeforeAvailabilityEndDay(item.availableDate),
  )

  const allItemsInStock = outOfStockItems ? false : true

  // 1st available Delivery Date based on Lead Time
  if (!allItemsInStock) {
    estimatedDelivery = response.pickupDeliveryDates?.selectedPickupDeliveryDate
  }

  const lastAvailableDeliveryDate = response?.pickupDeliveryDates?.dates
    .filter((d: IDates) => d.available && isBeforeAvailabilityEndDay(d.date))
    .slice(-1)
    .pop()

  lastAvailableDeliveryDateUTC = convertToUTC(lastAvailableDeliveryDate?.date)

  return {
    allItemsInStock,
    estimatedDeliveryToUTCDate,
    lastAvailableDeliveryDateUTC,
    lastLeadTimeDate,
    unavailableDates,
  }
}

const buildFinancePaymentRequest = (
  cartPrice: IMorCartPrice | null,
  formsState: ICheckoutFormState,
  sameBilling: boolean,
  cartItems: ICartItem[],
  morCardFinanceOptionSelected: IFinancePromotionOffer,
  dbuyComplete?: IDbuyCompleteResponse,
): IMorAuthRequest => {
  const paymentRequestBody = formsState

  const paymentRequest: IMorAuthRequest = {
    ...JSON.parse(JSON.stringify(paymentRequestBody)),
  }

  if (sameBilling) {
    paymentRequest.billing = paymentRequest.shipping
  }
  paymentRequest.cart = {
    items: cartItems,
    pricing: cartPrice,
    merceCartId: cartPrice?.merceCartId || '',
  }
  const financedPayment = {
    ...morCardFinanceOptionSelected,
    accountNumber: dbuyComplete?.data?.accountNumber,
    // this are required by GERS as is auth information for the payment.
    // accountNumber: '12345678',
    // authCode: '5678',
    authCode: dbuyComplete?.data?.authCode,
  }
  paymentRequest.financedPayment = financedPayment
  return paymentRequest
}

/**
 * If there is at least one product that is not in stock, GERS (the backend) needs to write to the database
 * 4/4/2040 as PickupDeliveryDate, while the user should see the correct date on the UI.
 *
 * The backend is padding one day so we need to send 4/5/2040, for the backend to save 4/4/2040.
 *
 * Also if needByDate was selected, then this will overwrite the deliveryDate for Mor to know when should the delivery be scheduled
 *
 * @param deliveryDate
 * @param needByDate
 * @param cartItems
 * @returns pickupDeliveryDateToWriteOnGers
 */
const getPickupDeliveryDateToWriteOnGers = (deliveryDate: any, needByDate?: string, cartItems?: any[]) => {
  let pickupDeliveryDateToWriteOnGers = needByDate && moment(needByDate).isValid() ? needByDate : deliveryDate
  const atLeastOneItemIsNotInStock = cartItems?.find(item => item.leadTime > 0 && item.availableMessage !== IN_STOCK)
  if (atLeastOneItemIsNotInStock) {
    pickupDeliveryDateToWriteOnGers = moment('2040-04-05').format('yyyy-MM-DDTHH:MM:SS.000+0000')
  }
  return pickupDeliveryDateToWriteOnGers
}

async function retryHandlePayment(
  transport: ITransport,
  request: IAuthPaymentRequest,
  retries = 3,
  initialDelay = 1000,
): Promise<IAuthPaymentResponse> {
  let response: IAuthPaymentResponse | null = null
  for (let attempt = 0; attempt <= retries; attempt++) {
    try {
      response = await PAYMENT_API_CALLS.AUTH_DOT_NET.initTransaction(transport, request)

      // If successful or non-retryable error, return immediately
      if (response.data || (response.error && response.error.messages)) {
        return response
      }

      throw new Error('Payment failed: ' + JSON.stringify(response.error))
    } catch (e) {
      const isLastAttempt = attempt === retries
      const anonId = getCookie('anonId', null) || ''
      logProviderFactory.logMessage(`initTransaction failed (attempt ${attempt + 1}) for cart Id: ${anonId} `, response)
      if (isLastAttempt) {
        return {
          data: null,
          error: 'Payment failed. Please try again.',
        }
      }

      // Increase delay on each retry (exponential backoff: 500ms, 1000ms, 2000ms, ...)
      const delay = initialDelay * Math.pow(2, attempt)
      await new Promise(res => setTimeout(res, delay))
    }
  }

  return {
    data: null,
    error: 'Retry attempts exhausted',
  }
}

export {
  isMorCreditCardFeatureEnabled,
  doGetPaymentAuthRequest,
  parseAuthResponse,
  creditCardFormHasError,
  updateSelectedState,
  validateShipping,
  validateContactInfo,
  getUnavailableItems,
  getZipInitialValue,
  isValidShipping,
  isBeforeAvailabilityEndDay,
  buildFinancePaymentRequest,
  getDateByLastLeadTime,
  isNeedByDateFeatureEnabled,
  loadDatesFromCart,
  getPickupDeliveryDateToWriteOnGers,
  validateBilling,
  retryHandlePayment,
}
