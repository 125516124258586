/* eslint-disable @typescript-eslint/member-delimiter-style */
import React from 'react'
import css from './CartModal.scss'
import ThemedButton from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Button/Button'
import { THEME_BUTTON_TYPES } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Theme/ThemeWrapper'
import { useState } from 'react'
import MorCartContext, {
  ICartCookies,
  IMorCartContextProviderProps,
  SAVE_CART_EMAIL_LOCAL_STORAGE,
  SAVE_CART_TIMESTAMP_LOCAL_STORAGE,
} from '../../Context/MorCartContext'
import getDefaultCmsTransport from '../../../../services/api/cmsInterceptor'
import {
  CART_COLLECTION_TYPE,
  ICartItem,
} from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Cart'
import { AxiosResponse } from 'axios'
import { setCookie } from '../../../../utils/cookie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import {
  removeAllPriceOverrideItems,
  removeAllTakenWithItems,
  removeInactiveItems,
} from '../../../../utils/cart/cartUtils'
import { IApiGetCartCollectionResponse } from '../../Services/CMS/cart'
import { CMS_API_CALLS } from '../../Services/CMS'
import { saveStorageValueWithExpiration } from '../../../../utils/localStorage'
import { KLAVIYO_API_CALLS } from '../../../../utils/klaviyo/klaviyoApiCalls'

export enum ICartModalType {
  SAVE = 'save',
  RETRIEVE = 'retrieve',
}

export interface IModalProps {
  onClose?: () => void
  onCartItemsRetrieved?: (items: ICartItem[], email: string) => void
  type: ICartModalType
  onExistingCartFound?: () => void
  onFound?: (email: string) => void
}

export interface ICartCollection {
  id: string
  _id: string
  items: ICartItem[]
  email: string
  type: CART_COLLECTION_TYPE
  uiCartId: string
}

const cartAttributes = {
  save: { title: 'Enter your email to save and view in-store:', buttonLabel: 'SAVE MY CART' },
  retrieve: { title: 'Enter the email associated with your cart:', buttonLabel: 'RETRIEVE CART' },
}

export const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

const CartModal = (props: IModalProps) => {
  const { cart, methods, getSaveCartEmail } = React.useContext(MorCartContext.Context) as IMorCartContextProviderProps
  const [showEmailFormatError, setShowEmailFormatError] = useState(false)
  const [showCartNotFoundError, setShowCartNotFoundError] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [showCartSaveError, setShowCartSaveError] = useState<string>('')
  const [emailInput, setEmailInput] = useState(getSaveCartEmail() || '')
  const [isLoadingMessage, setIsLoadingMessage] = useState<string>('')

  const { type, onClose, onFound } = props
  const { title, buttonLabel } = cartAttributes[type]

  const handleSaveCart = async () => {
    if (emailRegex.test(emailInput)) {
      const emailLowerCase = emailInput.toLowerCase()
      setShowEmailFormatError(false)
      setIsLoadingMessage('Saving your cart. Please wait...')
      try {
        await KLAVIYO_API_CALLS.onKlaviyoIdentifyByEmail(emailLowerCase)
        const existingCollectionResponse: IApiGetCartCollectionResponse = await CMS_API_CALLS.CART.getSavedCartCollection(
          emailLowerCase,
        )
        const existingCollectionId = existingCollectionResponse?.data?.[0]?.id
        setCookie(ICartCookies.SAVED_CART_RETRIEVED, true)
        if (!existingCollectionId) {
          saveStorageValueWithExpiration(SAVE_CART_EMAIL_LOCAL_STORAGE, emailLowerCase)
          const currentDate = new Date()
          saveStorageValueWithExpiration(SAVE_CART_TIMESTAMP_LOCAL_STORAGE, currentDate)
          const response = await CMS_API_CALLS.CART.saveCartCollection(emailLowerCase, cart)
          if (response.data) {
            await methods.saveCart({ cart, contactInfo: { emailAddress: emailLowerCase }, saveCartDate: currentDate })
            setShowSuccessMessage(true)
          } else {
            setShowCartSaveError(`There was an error saving your cart.`)
          }
        } else {
          if (props.onExistingCartFound) {
            props.onExistingCartFound()
          }
          if (onFound) {
            onFound(emailInput)
          }
          setShowSuccessMessage(true)
        }
      } catch (e) {
        setShowCartSaveError(`There was an error saving your cart: ${e.toString()}`)
        console.error(e)
      }
      setIsLoadingMessage('')
    } else {
      setShowEmailFormatError(true)
      setShowSuccessMessage(false)
    }
  }

  const handleRetrieveCart = async () => {
    console.time('time to retrieve saved cart')
    if (emailRegex.test(emailInput)) {
      const emailLowerCase = emailInput.toLowerCase()
      setShowEmailFormatError(false)
      setIsLoadingMessage('Retrieving your saved cart. Please wait...')
      try {
        const existingCollectionResponse: AxiosResponse = await getDefaultCmsTransport(false).get(
          `/cartcollections?email=${emailLowerCase}&type=${CART_COLLECTION_TYPE.SAVE_FOR_LATER}`,
        )
        const existingCollectionId: string | undefined = existingCollectionResponse?.data?.[0]?.id
        if (!existingCollectionId) {
          setShowCartNotFoundError(true)
        } else {
          const cartCollection: ICartCollection = existingCollectionResponse.data[0]
          setCookie(ICartCookies.SAVED_CART_RETRIEVED, true)
          setCookie('anonId', cartCollection.uiCartId)

          let cartCollectionItems = cartCollection.items
          cartCollectionItems = removeAllTakenWithItems(cartCollectionItems)
          cartCollectionItems = removeAllPriceOverrideItems(cartCollectionItems)
          cartCollectionItems = await removeInactiveItems(cartCollectionItems)
          if (props.onCartItemsRetrieved) {
            await props.onCartItemsRetrieved(cartCollectionItems, emailLowerCase)
          }
        }
        setIsLoadingMessage('')
        console.timeEnd('time to retrieve saved cart')
      } catch (e) {
        setShowCartSaveError(`There was an error retrieving your cart: ${e.toString()}`)
      }
    } else {
      setShowEmailFormatError(true)
    }
  }

  const renderSavedCartSuccessMessage = () => {
    return (
      <div>
        <h1 className={css.successTitle}>Success!</h1>
        <div className={css.successMessage}>
          Your cart has been saved. Use your email address to retrieve your saved cart online or with a store associate.
        </div>
      </div>
    )
  }

  const modalHasError = () => {
    return showEmailFormatError || showCartNotFoundError
  }

  const handleKeyPress = (event: any) => {
    const code = event.keyCode || event.which
    if (code === 13) {
      if (type === ICartModalType.SAVE) handleSaveCart()
      if (type === ICartModalType.RETRIEVE) handleRetrieveCart()
    }
  }

  const renderModalContent = () => {
    return (
      <>
        {!showSuccessMessage ? (
          <>
            <input
              autoFocus
              aria-label="Email"
              type="text"
              value={emailInput}
              onKeyPress={handleKeyPress}
              onChange={e => setEmailInput(e.target.value)}
              placeholder="Email*"
              className={modalHasError() ? `${css.emailInput} ${css.inputError}` : css.emailInput}
            />
            {showEmailFormatError && (
              <div className={css.errorMessage}>Please check the format of your email address.</div>
            )}
            {showCartNotFoundError && (
              <div className={css.errorMessage}>We did not find a saved cart for this email address.</div>
            )}
            {showCartSaveError && <div className={css.errorMessage}>{showCartSaveError}</div>}
            <ThemedButton
              className={modalHasError() ? `${css.modalButton} ${css.buttonError}` : css.modalButton}
              type={THEME_BUTTON_TYPES.PRIMARY}
              onClick={type === ICartModalType.SAVE ? handleSaveCart : handleRetrieveCart}
            >
              {buttonLabel}
            </ThemedButton>
          </>
        ) : (
          renderSavedCartSuccessMessage()
        )}
      </>
    )
  }

  return (
    <React.Fragment>
      <div className={`${css.modalBackground}`} />
      <div className={css.modalBody}>
        <div className={css.innerBody}>
          <div className={css.titleContainer}>
            <div onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} fixedWidth size="xs" className={css.close} />
            </div>
            {isLoadingMessage ? <div /> : !showSuccessMessage && <h1 className={css.title}>{title}</h1>}
          </div>
          <div className={css.modalContent}>
            {isLoadingMessage ? <div>{isLoadingMessage}</div> : renderModalContent()}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CartModal
