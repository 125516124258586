import Axios from 'axios'
import getConfig from 'next/config'
import { GERS_SERVICE, ORIGIN_CODE_WEB } from '../../../../../settings/variables'
import { ICartItem } from '../../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Cart'
import { isCustomProductMetadata } from '../../../../Utils/customProductUtils'
import { getProductFinalPrice } from '../../../../Utils/productUtils'
import { IContactInfo } from '../../../Context/MorCartContext'
import { ICustomActiveOptions } from '../../../Layout/PDP/PDP'
import { IPickupDeliveryType } from './delivery'
import { IMethodOfPayment } from './order'
import logProviderFactory from '../../../../../utils/logs/logProviderFactory'
import { removeAllPriceOverrideItems } from '../../../../../utils/cart/cartUtils'

const { publicRuntimeConfig } = getConfig()
export const CMS_API = publicRuntimeConfig.CMS_API || 'http://localhost:1337'
export const CACHE_ENDPOINT = publicRuntimeConfig.CACHE_ENDPOINT || ''

export interface IGersCalculateCartResponse {
  error: string
  data: IGersCalculateCart | null
}

export interface IGersCalculateCart {
  shoppingCartID: number
  deliveryCharge: number
  recyclingFee: number
  safeguardCharge: number
  salesTaxLabel: string
  subtotalLabel: string
  salesTax: number
  promoSavings: number
  pickupDeliveryFees: number
  pickupDeliveryLabel: string
  pickupDeliveryDate: string
  recyclingFeeLabel: string
  recyclingFeeInfo: string
  subtotal: number
  orderTotal: number
  items: ICalculateCartItems[]
  pickupDeliveryType: string
  promo1Label?: string
  promo1Savings?: number
  promo2Label?: string
  promo2Savings?: number
  promo3Label?: string
  promo3Savings?: number
  promoSavingsLabel?: string
  promoSavingsTotal?: number
  coupon1Label?: string
  coupon1Savings?: number
  coupon2Label?: string
  coupon2Savings?: number
  coupon3Label?: string
  coupon3Savings?: number
  couponSavingsLabel?: string
  couponSavingsTotal?: number
  mattressDeliveryLabel?: string
  mattressDeliverySavings?: number
  addPromo1Label1?: string
  addPromo1Label2?: string
  addPromo2Label1?: string
  addPromo2Label2?: string
  addPromo3Label1?: string
  addPromo3Label2?: string
  safeguardFee?: number
  methodsOfPayment: IMethodOfPayment[]
  isFinanced?: boolean
  delDiscAmount: number
  taxDiscAmount: number
  customOrderSubtotal: number
  customOrderDepAmount: number
  errorCode: string
}

export interface ICalculateCartItems {
  sku: string
  finalPrice: number
  extendedPrice: number
  availableMessage: string
  promoLabel1?: string
  promoLabel2?: string
  promoLabel3?: string
  rfIdCode?: string
  customOptionValues: ICustomActiveOptions[]
}

export interface ICalculateCartRequest {
  items: any[]
  storeCode: string
  zipCode?: string
  pickupDeliveryType: IPickupDeliveryType
  pickupStoreCode?: string
  merceCartId?: string
  employeeCode: string
  employeeCode2: string
  populateSalespersonName?: boolean
  customerCode?: string
  methodsOfPayment?: IMethodOfPayment[]
  isFinanced?: boolean
  zoneCode?: string | null
  contactInfo?: IContactInfo
  saveCartDate?: Date
}

export interface IGersValidatedCart {
  merceCartId: string
  shoppingCartID: number
  skus: IValidatedItemInfo[]
}

export interface IValidatedItemInfo {
  available: boolean
  sku: string
}

const ENDPOINTS = {
  CALCULATE_CART: 'carts/calculateCart',
  SAVE_CART: 'carts/v2/saveCart',
  CART_V3: 'carts/v3/cart',
  SAVE_CART_BEFORE_CALCULATE: 'carts/v2/saveCartBeforeCalculate',
}

interface ICartAction {
  endpoint: string
  logMessage: string
}

interface ICartActions {
  [x: string]: ICartAction
}

const cartActions: ICartActions = {
  SAVE: {
    endpoint: `${GERS_SERVICE}/${ENDPOINTS.SAVE_CART}`,
    logMessage: 'Request save cart...',
  },
  CALCULATE: {
    endpoint: `${GERS_SERVICE}/${ENDPOINTS.CALCULATE_CART}`,
    logMessage: 'Request calculate cart...',
  },
  SAVE_CART: {
    endpoint: `${GERS_SERVICE}/${ENDPOINTS.CART_V3}`,
    logMessage: 'Request save cart v3...',
  },
}

const mapItems = (items: ICartItem[], storeCode?: string) => {
  let validRequest = true
  const mappedItems = items.map((item: any) => {
    const product = item.product
    const finalPrice = getProductFinalPrice(product, storeCode || '')
    const { quantity, metaData } = item
    const rfIdCode = metaData?.rfid?.rfId
    const sku = product['sku']
    if (!sku) {
      const uniqueId = product['uniqueId']
      const id = product['id']
      const name = product['name']
      console.log(`sku is required for item array - uniqueId: ${uniqueId} - id: ${id} -  name: ${name}`)
      validRequest = false
      return
    }

    items = removeAllPriceOverrideItems(items)

    let customOptionValueIds = null
    if (isCustomProductMetadata(metaData)) {
      const selectedOptions = metaData.cylindoConfig.selectedOptions as ICustomActiveOptions[]
      customOptionValueIds = selectedOptions.map(addon => {
        return {
          customOptionValueId: addon.customOptionValueId,
        }
      })
    }
    const mappedItem = {
      finalPrice,
      quantity,
      safeguard: metaData?.safeguard,
      sku,
      rfIdCode,
      ...(item.metaData?.priceOverride && { priceOverride: item.metaData?.priceOverride }),
      customOptionValues: customOptionValueIds,
    }
    return mappedItem
  })
  return {
    mappedItems,
    validRequest,
  }
}

const getCalCartRequestBody = (params: ICalculateCartRequest, items: any[]): ICalculateCartRequest => {
  const body = {
    items: items,
    pickupDeliveryType: params.pickupDeliveryType,
    pickupStoreCode: params.pickupStoreCode,
    storeCode: params.storeCode,
    zipCode: params.zipCode,
    merceCartId: params.merceCartId || '0',
    originCode: ORIGIN_CODE_WEB,
    employeeCode: params.employeeCode || '',
    employeeCode2: params.employeeCode2 || '',
    populateSalespersonName: false,
    customerCode: params.customerCode || '',
    methodsOfPayment: params.methodsOfPayment || [],
    isFinanced: params.isFinanced || false,
    zoneCode: params.zoneCode || null,
    contactInfo: params.contactInfo,
    saveCartDate: params.saveCartDate,
    emailAddress: params.contactInfo?.emailAddress,
  }

  return body
}

const generateCacheKey = (body: any): string => {
  // Create a deep copy of the body to avoid mutating the original request
  const bodyCopy = JSON.parse(JSON.stringify(body))

  delete bodyCopy.zoneCode

  return JSON.stringify(bodyCopy)
}

const CACHE_KEY = 'cartCache'
const CACHE_TTL = 1 * 60 * 60 * 1000 // 1 hour in milliseconds

const getCache = (): Record<string, { data: IGersCalculateCartResponse; timestamp: number }> => {
  const cache = localStorage.getItem(CACHE_KEY)
  return cache ? JSON.parse(cache) : {}
}

const setCache = (key: string, value: IGersCalculateCartResponse) => {
  const cache = getCache()
  cache[key] = {
    data: value,
    timestamp: Date.now(),
  }
  localStorage.setItem(CACHE_KEY, JSON.stringify(cache))
}

const isCacheValid = (timestamp: number): boolean => {
  return Date.now() - timestamp < CACHE_TTL
}

const executeCartAction = async (
  request: ICalculateCartRequest,
  action: ICartAction,
): Promise<IGersCalculateCartResponse> => {
  try {
    const mappedItems = mapItems(request.items, request.storeCode)

    if (mappedItems.validRequest) {
      const endpoint = action.endpoint
      const body = getCalCartRequestBody(request, mappedItems.mappedItems) as any
      console.log(action.logMessage, body)
      logProviderFactory.logMessage(`Calling calculateCart for merceCartId: ${[request.merceCartId]}.`, body)

      console.log('Request saveCartBeforeCalculate...', body)
      const savedCartEndpoint = `${GERS_SERVICE}/${ENDPOINTS.SAVE_CART_BEFORE_CALCULATE}`
      const savedCartResponse = await Axios.post(savedCartEndpoint, body)
      console.log('Response saveCartBeforeCalculate...', savedCartResponse)
      body.id = savedCartResponse.data

      const cacheKey = generateCacheKey(body)

      // Check localStorage cache
      const cache = getCache()
      const cachedEntry = cache[cacheKey]

      if (cachedEntry && isCacheValid(cachedEntry.timestamp)) {
        console.log(`Cache hit for ${cacheKey}`)
        return cachedEntry.data
      }

      console.time(action.logMessage)
      const response = await Axios.post(endpoint, body)
      console.timeEnd(action.logMessage)
      const result: IGersCalculateCartResponse = {
        error: '',
        data: response.data,
      }

      // Save response to localStorage
      setCache(cacheKey, result)

      return result
    } else {
      return {
        error: 'An error has occurred...',
        data: null,
      }
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

const saveCart = async (request: ICalculateCartRequest): Promise<IGersCalculateCartResponse> => {
  return await executeCartAction(request, cartActions.SAVE)
}

const calculateCart = async (request: ICalculateCartRequest): Promise<IGersCalculateCartResponse> => {
  return await executeCartAction(request, cartActions.CALCULATE)
}

const saveCartV3 = async (request: ICalculateCartRequest): Promise<IGersCalculateCartResponse> => {
  return await executeCartAction(request, cartActions.SAVE_CART)
}

export default {
  calculateCart,
  saveCart,
  saveCartV3,
}
