import { IMAGE_PROXY_URL } from '../settings/variables'
import stripTrailingSlash from '../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/stripTrailingSlash'

export enum IMAGE_PROXY_TYPES {
  JPEG = 'jpeg',
  PNG = 'png',
  WEBP = 'webp',
}

export interface IImageProxyFitParams {
  size?: number | string
  quality?: number
  type?: IMAGE_PROXY_TYPES
}

const fixUrl = (url: string) => {
  if (url) {
    url = url.trim()
    url.replace(/\(/i, '%28').replace(/\)/i, '%29')
  }
  return url
}

const fit = (url: string, params?: IImageProxyFitParams) => {
  if (url.indexOf(IMAGE_PROXY_URL || '') !== -1) {
    return url
  }
  const size: number | string = params?.size ?? '300x0'
  const quality: number = params?.quality ?? 90
  const splitedArray: string[] = url.split('.')
  const lastValueOfArray = splitedArray[splitedArray.length - 1]
  const isGif = lastValueOfArray === 'gif'
  const type: string = isGif ? 'gif' : params?.type ?? IMAGE_PROXY_TYPES.JPEG
  if (IMAGE_PROXY_URL) {
    const safeProxyUrl: string = stripTrailingSlash(IMAGE_PROXY_URL)
    return `${safeProxyUrl}/${size},fit,q${quality},${type}/${url}`
  }
  return url
}

const getFileTypeFromURL = (url: string) => {
  const splitedArray: string[] = url.split('.')
  const lastValueOfArray = splitedArray[splitedArray.length - 1]
  if (
    lastValueOfArray === 'mp4' ||
    lastValueOfArray === 'flv' ||
    lastValueOfArray === 'm4a' ||
    lastValueOfArray === '3gp' ||
    lastValueOfArray === 'mkv'
  ) {
    return 'video'
  } else if (lastValueOfArray === 'mp3' || lastValueOfArray === 'ogg') {
    return 'audio'
  } else if (lastValueOfArray === 'jpg' || lastValueOfArray === 'png' || lastValueOfArray === 'gif') {
    return 'image'
  } else {
    return ''
  }
}

export { fit, getFileTypeFromURL, fixUrl }
